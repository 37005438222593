.header {
  background: @color01;
  border-bottom: 1px solid @color05;
  box-shadow: 2px 2px 5px @color04;
  height: 70px;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;

  .fix {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: @sitemaxwidth;
    padding: 10px 0;
    position: relative;
    width: @sitewidth;
    z-index: 2;
  }

  .dark {
    background: @color04; // url(/wp-content/themes/baseTheme/src/images/hf.png) top left repeat;
    background-size: 400px;
    bottom: 0;
    left: 0;

    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.mail {
  background: @color01;
  border-radius: 5px;
  border: 1px solid @color02;
  color: @color02;
  font-size: 16px;
  font-weight: 600;
  height: 26px;
  padding: 2px 8px 3px 8px;
  text-decoration: none;
}

@media (max-width:425px) {
  .ya-share2 {
    display: none;
  }
}