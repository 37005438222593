@font-face {
  font-family: "RobotoRegular";
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoRegular.eot");
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoRegular.eot?#iefix")format("embedded-opentype"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoRegular.woff") format("woff"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoLight.eot");
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoLight.eot?#iefix")format("embedded-opentype"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoLight.woff") format("woff"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMedium";
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoMedium.eot");
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoMedium.eot?#iefix")format("embedded-opentype"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoMedium.woff") format("woff"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoBold.eot");
  src: url("/wp-content/themes/baseTheme/build/fonts/RobotoBold.eot?#iefix")format("embedded-opentype"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoBold.woff") format("woff"),
       url("/wp-content/themes/baseTheme/build/fonts/RobotoBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}