.similar {
  margin: 0 auto;
  max-width: @sitemaxwidth;
  width: @sitewidth;

  &__title {
    font-size: 30px;
    font-weight: 400;
    margin-top: 30px;
  }
}

@media screen and (max-width:1100px) {
  .similar {
    &__title {
      text-align: center;
    }
  }
}