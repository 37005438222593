.page {
  margin: 0 auto;
  max-width: @sitemaxwidth;
  width: @sitewidth;

  &__title {
    border-left: 5px solid @color02;
    font-family: RobotoLight, Verdana, Arial, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 30px;
    margin: 40px 0;
    padding: 0 0 5px 30px;
  }
}

.spec {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 2px solid #cfcfcf;
  padding-bottom: 20px;
}

.spec-item {
  display: flex;
  justify-content: space-between;
  width: 48%;
  padding: 5px;

}

.sc_type,
.nk {
  display: block;
}

.sc_type {
  text-align: left;
  font-weight: bold;
}
.nk {
  text-align: right;
}

.post .main_information {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 0px;

}

.main_information_list {
  width: calc(50% - 10px);

  & p {
    margin: 0px !important;
  }

}

.post .main_information .main_information_list {
  margin: 5px 0;
}

.post .main_information_value {
  font-size: 18px;

}

.post .main_information_caption {
  font-size: 14px;
  color: #1c2b39a3;
}

.post .main_information_title {
  font-size: 20px;
  text-align: left;
  padding: 0;
  margin: 15px 10px 10px 40px;
}

.post .conditions {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 0px;
}

.conditions_list {
  width: calc(50% - 10px);

  & p {
    margin: 0px !important;
  }
}

.post .conditions .conditions_list {
  margin: 5px 0;
}

.post .conditions_value {
  font-size: 18px;

}

.post .conditions_caption {
  font-size: 14px;
  color: #1c2b39a3;
}

.post .conditions_title {
    font-size: 20px;
    text-align: left;
    padding: 0;
    margin: 15px 10px 10px 40px;
}



