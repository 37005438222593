.cat {
  margin: 0 auto;
  max-width: @sitemaxwidth;
  width: @sitewidth;

  &title {
    color: @color02;
    font-family: 14px/1.22 Ubuntu, Arial, sans-serif;
    font-size: 30px;
    margin: 40px auto;
    max-width: @sitemaxwidth;
    width: @sitewidth;
  }
}

.mr-5 {
  margin-right: 5px;
}

.items {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0;
}

.ibig {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 240px;
  margin-bottom: 50px;
  text-decoration: none;
  width: 49%;

  &__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 65%;
  }

  &__thumb {
    background-position: center center;
    border-radius: 15px;
    height: 100%;
    width: 31%;
  }

  &__cat {
    border-bottom: 1px solid @color02;
    color: @color02;
    font-size: 14px;
  }

  &__title {
    color: @color02;
    font-size: 20px;
    font-weight: 700;
    margin: 30px 0 35px 0;
  }

  &__btn {
    background: @color03;
    border-radius: 50%;
    color: @color01;
    height: 33px;
    padding: 6px 0 6px 13px;
    width: 33px;

    .fas {
      color: @color01;
    }
  }
}

.iurl {
  color: @color02;
  font-weight: 700;
  margin: 5px 0;
  text-decoration: none;
  width: 49%;
  background: #ededed;
  border-radius: 10px;
  padding: 8px;


  span {
    border-bottom: 1px solid @color02;
    color: @color02;
  }
}

.imini {
  height: 240px;
  margin-top: 50px;
  position: relative;
  width: 15%;

  &__thumb {
    border-radius: 15px;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &__title {
    bottom: 45px;
    color: @color01;
    font-size: 15px;
    font-weight: 700;
    left: 20px;
    position: absolute;
    right: 20px;
    z-index: 2;
  }

  &__cat {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    left: 20px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 2;
  }

  &:hover &__thumb {
    opacity: 0.8;
    transition: all .1s;
  }
}


@media all and (max-width:1100px) {
  .title {
    text-align: center;
  }
}

@media all and (max-width:900px) {
  .imini {
    width: 32%;
  }

  .iurl {
    width: 49%;
  }

  .ibig {
    width: 49%;

    &__thumb {
      width: 49%;
    }

    &__info {
      width: 49%;
    }
  }
}

@media all and (max-width:600px) {
  .imini {
    width: 49%;
  }

  .iurl {
    width: 49%;
  }

  .ibig {
    width: 100%;

    &__thumb {
      width: 49%;
    }

    &__info {
      width: 49%;
    }
  }
}

@media all and (max-width:450px) {
  .imini {
    width: 49%;
  }

  .iurl {
    width: 100%;
  }

  .ibig {
    width: 100%;

    &__thumb {
      width: 49%;
    }

    &__info {
      width: 49%;
    }
  }
}

@media all and (max-width:300px) {
  .imini {
    width: 100%;
  }

  .iurl {
    width: 100%;
  }

  .ibig {
    width: 100%;

    &__thumb {
      width: 49%;
    }

    &__info {
      width: 49%;
    }
  }
}