.breadcrumbs {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 4px 0 !important;

  .item {
    margin: 0 !important;
  }

  .separator {
    color: @color03 !important;
    margin: 0 10px !important;
  }

  .link {
    color: @color03 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}