.footer {
  margin: 0 auto;
  max-width: @sitemaxwidth;
  padding: 10px 0;
  text-align: center;
  width: @sitewidth;

  .fa-sitemap {
    color: @color03;
    margin-right: 5px;
  }

  .block {
    margin: 20px 0;

    p:nth-child(1), p:nth-child(2) {
      font-size: 12px;
      line-height: 20px;
    }

    p:nth-child(2) {
      margin-bottom: 10px;
    }

    p:nth-child(3), p:nth-child(4) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ul {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 20px auto 30px auto;
    max-width: @sitewidth;
    width: @sitemaxwidth;
  }

  span {
    color: @color03;
  }

  a {
    color: @color02;
    cursor: pointer;
    text-decoration: none;

    span {
      border-bottom: 2px solid @color03;
      color: @color02;
    }
  }
}

@media (max-width:1024px) {
  p {
    text-align: center;
  }
}