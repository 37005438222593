.logo {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .img {
    width: 90px;
  }

  .link {
    color: @color02;
    font-family: RobotoBold, Verdana, sans-serif;
    font-size: 24px;
    margin: 0 0 0 20px;
    text-decoration: none;
  }

  span {
    color: @color03;
  }
}