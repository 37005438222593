.information {
  margin: 20px 0;

  .span {
    font-family: RobotoBold, Verdana, sans-serif;
    margin-left: 5px;
  }

  .date {
    margin: 2px 0;
  }

  .fa-calendar-alt, .fa-user {
    color: @color03;
    font-size: 13px;
    margin-right: 0 !important;
  }

  .author {
    margin: 15px 0 2px 0;
  }
}


