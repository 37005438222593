.post {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  margin: 25px auto;
  max-width: @sitemaxwidth;
  width: @sitewidth;

  &content {
    padding: 0 1% 0 0;
    width: 50%;
  }

  &sidebar {
    background: #ededed;
    padding: 0 1%;
    width: 50%;
    border-radius: 10px;
  }

  &title {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 30px 0;
    text-align: center;
  }

  &menu {
    background: @color04;
    list-style: none;
    margin: 0 !important;
    padding: 20px;

    &title {
      color: @color02;
      font-family: RobotoLight, Verdana, sans-serif;
      font-size: 22px;
      line-height: 30px;
      margin: 15px 0;
      text-align: center;
    }

    a {
      color: @color02 !important;
      font-size: 16px;
      font-weight: 400 !important;
      text-decoration: none;
    }

    .fa.fa-bookmark-o {
      margin-right: 30px;
    }
  }

  img {
    height: auto;
    width: 100% !important;
    border-radius: 10px
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin: 10px 0;
    overflow: hidden;
    text-align: justify;
  }

  strong {
    font-family: RobotoBold, Verdana, sans-serif;
  }

  h2 {
    color: @color02;
    font-family: RobotoLight, Verdana, sans-serif;
    font-size: 22px;
    line-height: 30px;
    margin: 15px 0;
  }

  h3 {
    padding: 12px;
    text-align: center;
  }

  ol, ul {
    font-size: 16px;
    margin: 15px 0 15px 40px;

    li {
      margin: 10px 0;
    }
  }

  a {
    color: @color03;
    font-weight: 700;
  }

  blockquote {
    background: @color04;
    border-left: 7px solid @color03;
    margin: 20px 0;
    padding: 5px 20px;
    border-radius: 10px;
  }
}


@media (max-width:800px) {
  .post {
    &content {
      width: 100%;
    }

    &sidebar {
      width: 100%;
    }

    p {
      text-align: left;
    }
  }
}