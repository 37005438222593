aside,
body,
button,
div,
footer,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
header,
html,
img,
input,
li,
main,
nav,
ol,
p,
pre,
section,
span,
table,
td,
textarea,
tr,
ul,
a {
  box-sizing: border-box;
  line-height: 120%;
  margin: 0;
  padding: 0;
}