.tags {
  margin-top: 30px;

  &__title {
    font-size: 20px;
    font-weight: 700;
  }

  &__list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0 0 30px 0 !important;
  }

  &__item {
    margin: 0 15px 10px 0 !important;
  }

  a {
    border-bottom: 1px solid @color02;
    color: @color02;
    font-size: 14px;
    text-decoration: none;
  }
}