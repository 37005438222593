.menu {

  border-bottom: 1px solid @color05;
  box-shadow: 2px 2px 5px @color04;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 80px;
  z-index: 9998;

  ul {
    background: @color01;
    list-style: none;
    margin: 0 auto;
    max-width: @sitemaxwidth;
    padding: 20px;
    width: 100%;
  }

  i {
    color: @color03;
    margin-right: 10px;
  }

  a {
    color: @color02;
    text-decoration: none;
  }

  li {
    margin: 10px 0;
  }
}

.fa-bars {
  cursor: pointer;
  font-size: 24px;
}

.fa-telegram-plane {
  cursor: pointer;
  font-size: 26px;
}

.body_pointer * {
  cursor: pointer;
}

.my_menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    list-style: none;
    align-items: center;

    li {
      margin: 40px;
      
    }

    a {
    text-decoration: none;
    color: #000;
    font-size: medium;
    font-weight: 600;
    padding: 7px 16px 8px;
    border-radius: 5px;
    border: solid #0c86ab;
    }
}
