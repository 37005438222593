.search {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 10px auto;
  max-width: @sitemaxwidth;
  width: @sitewidth;

  .text {
    background: url(/wp-content/themes/baseTheme/src/images/search.svg) 10px 10px no-repeat;
    background-size: 22px;
    border-radius: 5px;
    border: 1px solid @color05;
    box-shadow: 0 0 5px @color04;
    font-size: 16px;
    padding: 10px 20px 10px 40px;
    width: calc(100% - 160px);
  }

  .submit {
    background: @color03;
    border-radius: 5px;
    border: none;
    color: @color01;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 0;
    width: 136px;
  }
}