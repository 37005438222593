.wsp-pages-list {
  margin: 10px 0 10px 50px;
}

.page_item, .wsp-post {
  margin: 5px 0;
}

.wsp-post a {
  color: @color02;
  text-decoration: none;
}

.page_item a {
  color: @color02;
  text-decoration: none;
}

.wsp-category-title a {
  color: @color02;
  text-decoration: none;
}

.wsp-posts-list {
  margin: 10px 0 10px 50px;
}