.comments {

  &Caption {
    font-size: 30px !important;
    font-weight: 400 !important;
    margin: 30px 0 !important;
  }

  &__name {
    border-bottom: 1px solid @color02;
    color: @color03;
    font-size: 17px;
    font-weight: 700;
  }

  &__date {
    float: right;
    font-size: 14px;
  }

  &__reply a {
    color: @color02;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
  }
}

.comment {
  margin: 20px 0 !important;
}

.commentlist {
  list-style: none;
  margin: 0 !important;
}

.children {
  list-style: none;
  margin: 10px 0 10px 60px !important;

  li {
    margin: 10px 0 !important;
  }
}

.comment-form {
    width: 100%;
    resize: none;
    padding: 10px 20px 12px;
    border-radius: 5px;
    border: none;
}

.author {
    padding: 10px 20px 12px;
    border-radius: 5px;
    border: none;
}

.email {
    padding: 10px 20px 12px;
    border-radius: 5px;
    border: none;
}

.submit {
    background: @color03;
    border-radius: 5px;
    border: none;
    padding: 12px 20px 15px;
    color: #ffffff;
}